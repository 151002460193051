<template>
  <div class="page">
    <van-empty image="network" :description="$t('notfound')">
      <van-button type="primary" plain @click="$router.push('/')"
        >{{ $t("back") }}</van-button
      >
    </van-empty>
  </div>
</template>
<script>
import { hideWechatOptionMenu } from "@/utils/util";
import { defineComponent, onMounted } from "@vue/runtime-core";
export default defineComponent({
  setup() {
    onMounted(() => {
      hideWechatOptionMenu();
    });
  },
});
</script>
